<template>
  <div>
    <Confirmacion
      :pregunta="consultaDialog"
      :consulta="preguntaConsulta"
      :loading="loadingPregunta"
      @si="confirmacionAceptada"
      @no="consultaDialog=false"
    />
    <v-row>
      <v-col cols="12">
        <v-row v-if="add">      
          <v-col cols="12">   
          <NuevoFormacion 
            @creado="creado"
            :urlAdd="urlAdd"
            :globalAdd="globalAdd"
          />
          </v-col>
        </v-row>
        <small v-show="select">Formación Seleccionada {{selectedIds.length}}</small>
        <v-data-table
          v-model="selected"
          :headers="computedHeaders"
          :items="desserts"
          item-key="id"
          :server-items-length="totalDesserts"
          :hide-default-footer="hiddenFoter"
          noDataText="No hay ninguna Formación Registrada para el Abogado"
          :mobile-breakpoint="100"
          calculate-widths
         :show-select="select"
         :loading="loadingTable"
          loading-text="Cargando Datos"
        >
          <template v-slot:item.acciones="{item}">
            <v-icon
              v-if="item.estado==='CREADO'"
              small
              color="error"
              class="mr-2"
              @click="eliminarFormacion(item)"
            >mdi-trash-can</v-icon>
          </template>
          <template v-slot:item.verificacion="{ item }">
            <v-chip
              small
              :color="colorEstado(item.estado)"
              text-color="white"
            >{{item.estado}}</v-chip>
          </template>
          <template v-slot:item.gradoFormacion="{ item }">
            {{ getNombreParametro(item,'grado_formacion')}}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Mabogado from "@/mixins/abogado"
import Confirmacion from "@/common/util/Confirmacion"
import mixinParametro from  "@/mixins/parametro"
export default {
  props:{
    select:{
      type:Boolean,
      default:false
    },
    actions:{
      type:Boolean,
      default:true
    },
    formaciones:{
      type: Array,
      default:()=>{
        return []
      }
    },
    general:{
      type:Boolean,
      default:true
    },
    add:{
      type:Boolean,
      default:true
    },
    datosCreacion:{
      type:Boolean,
      default:false
    },
    areas:{
      type: Array,
      default:()=>{
        return []
      }
    },
    urlAdd:{
      type: String,
      default: 'abogado-ciudadano/formacion'
    },
    globalAdd:{
      type: Boolean,
      default: true
    }
  },
  components: {
    'NuevoFormacion':()=>import("@/components/NuevaFormacion"),
    Confirmacion
  },
  mixins: [Mabogado, mixinParametro],
  data() {
    return {
      consultaDialog: false,
      preguntaConsulta: "",
      tipoConsulta: null,
      formacionSeleccionada: {},
      selected: [],
      titulo: "Registro Formaciones Académicas",
      pregunta: false,
      tipo: false,
      consulta: "",
      loadingPregunta: false,
      modelEdit: null,
      editedIndex: -1,
      selected: [],
      headers: [
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "acciones",
          width: "70px"
        },
        {
          text: "Nombre Formación",
          align: "left",
          value: "nombre",
          sortable: true,
          width: "200px"
        },
        {
          text: "Estado",
          align: "left",
          sortable: false,
          value: "verificacion",
          width: "150px"
        },
        {
          text: "Institución",
          align: "left",
          value: "institucion",
          sortable: true,
          width: "200px"
        },
        {
          text: "Grado",
          align: "left",
          sortable: false,
          value: "gradoFormacion",
          width: "150px"
        }
      ],
      hiddenFoter: true
    };
  },
  async created(){
    if (this.general && this.$store.state.abogado.formacion.length === 0) {
      await this.getFormacionAbogado();
    }
  },
  computed: {
    totalDesserts() {
      if (this.desserts.length > 8) {
        this.hiddenFoter = false;
      }
      return this.desserts.length;
    },
    selectedIds() {
      var ids = [];
      if (this.selected.length > 0) {
        this.selected.forEach(item => {
          ids.push(item.id);
        });
      }
      this.$emit("formacionSelecionada", ids);
      return ids;
    },
    desserts() {
      return this.general? this.$store.state.abogado.formacion:this.formaciones;
    },
    computedHeaders(){
       if(!this.actions){
         let i = this.headers.map(item => item.text).indexOf('Acciones')
            this.headers.splice(i,1);
      }
      return this.headers;
    }
  },
  methods: {
    colorEstado(data) {
      const colores= {
          CREADO:'grey',
          VERIFICADO:'primary',
          CERTIFICADO:'success',
          ANULADO:'error',
          RECHAZDO:'error'
      }
      return colores[data]
    },
    eliminarFormacion(item) {
      Object.assign(this.formacionSeleccionada, item);
      this.preguntaConsulta = `¿Está Seguro de Eliminar la Formación Académica <strong>" ${this.formacionSeleccionada.nombre} "</strong> ?.`;
      this.consultaDialog = true;
    },
    confirmacionAceptada() {
      this.loadingPregunta = true;
      this.deleteFormacion(this.formacionSeleccionada.id)
    },
    creado(data){
        if(this.add && this.select){
          if(!this.selected.some(e=>e.id===data.id)){
            this.selected.push(data)
          }
        }else if(this.add && !this.globalAdd){
          this.$emit('nuevo',data)
        }
    }
  }
};
</script>
<style scoped>
</style>
